import React from 'react'
import styled from 'styled-components'
import EmailImg from '../assets/images/contact-2.jpg'
import { Link } from 'gatsby'

const Email = () => {
    return (
        <EmailContainer>
            <EmailContent>
                <h1>Get Access to Exclusive Updates</h1>
                <p>Sign up for your newsletter below to get the updated information!</p>
                <form action="#">
                    <FormWrap>
                        <label htmlFor="email">
                            <input type="email" placeholder="Enter your email" id="email" />
                        </label>
                        <Button as="button" type="submit" primary="true" round="true" >Sign Up</Button>
                    </FormWrap>
                </form>
            </EmailContent>
        </EmailContainer>
    )
}

export default Email

const EmailContainer = styled.div`
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0.5) 35%,
        rgba(0, 0, 0, 0.1) 100%
    ),
    url(${EmailImg}) no-repeat center;
    background-size: cover;
    height: 450px;
    width: 100%;
    padding: 5rem calc((100vw - 1300px) / 2);
    color: #fff;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100vh;
`
const EmailContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 33vh;
    h1{
        text-align: center;
        margin-bottom: 1rem;
        font-size: clamp(1rem, 5vw, 3rem);
        padding: 0 1rem;  
    }

    p{
        text-align: center;
        font-size: clamp(1rem, 2.5vw, 1.5rem);
        padding: 0 1rem;
        margin-bottom: 2rem;
    }

    form{
        z-index: 10;
    }
`
const FormWrap = styled.div`
    input{
        padding: 1rem 1.5rem;
        outline: none;
        width: 350px;
        height: 48px;
        border-radius: 50px;
        border: none;
        margin-right: 1rem;
    }

    @media screen and (max-width: 768px){
        display: flex;
        flex-direction: column;
        padding: 0 1rem;

        input {
            margin-bottom: 1rem;
            width: 100%;
            margin-right: 0;
        }
    }
`

const Button = styled (Link)`
    background:#F26A2E;
    white-space: nowrap;
    padding: 10px 32px;
    color: #fff;
    font-size: '16px';
    outline: none;
    border: none;
    min-width: 100px;
    cursor: pointer;
    text-decoration: none;
    transition: 0.3s !important;
    border-radius: 50px;
    height: 48px;

    &:hover {
        background: #077BF1;
        transform: translateY(-2px);
    }

    @media screen and (max-width: 768px){
        width: 100%;
        min-width: 350px;
    }

    @media screen and (max-width: 400px){
        width: 100%;
        min-width: 250px;
    }

`