import React from 'react'
import { MdTimer } from 'react-icons/md'
import { FaPrescription, FaPrescriptionBottleAlt } from 'react-icons/fa'
import { RiDiscussLine } from 'react-icons/ri'

export const HowItWorksData = [
    {
        icon: (<FaPrescription style={{color: "#047bf1"}}/>),
        title: "Step 1",
        desc: "Receive doctor's prescription"
    },
    {
        icon: (<MdTimer style={{color: "#f3a82e"}}/>),
        title: "Step 2",
        desc: "Schedule  visit at home or work"
    },
    {
        icon: (<FaPrescriptionBottleAlt style={{color: "#f34f2e"}}/>),
        title: "Step 3",
        desc: "Complete specimen collection"
    },
    {
        icon: (<RiDiscussLine style={{color: "#3af576"}}/>),
        title: "Step 4",
        desc: "Discuss results with your doctor"
    }
]