import React from 'react'
import styled from 'styled-components'
import { HowItWorksData } from '../data/HowItWorksData'
import Pulse from 'react-reveal/Pulse';

const HowItWorks = () => {
    return (
        <HowItWorksCotnainer>
            <Pulse>
                <Heading>
                    How it works?
                </Heading>
                <Wrapper>
                    {HowItWorksData.map((item, index)=> {
                        return(
                            <HowItWorksBox key={index}>
                                <Icon>{item.icon}</Icon>
                                <Title>{item.title}</Title>
                                <Description>{item.desc}</Description>
                            </HowItWorksBox>
                        )
                    })}
                </Wrapper>
            </Pulse>
        </HowItWorksCotnainer>
    )
}

export default HowItWorks

const HowItWorksCotnainer = styled.div`
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #bababa;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4rem calc((100vw - 1300px) / 2);
`
const Heading = styled.div`
    text-align: start;
    font-size: clamp(1.5rem, 5vw, 2rem);
    margin-bottom: 3rem;
    /* padding: 0 2rem; */
    font-weight: bold;   

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        margin-left: 30px;
    }

    @media screen and (max-width: 500px) {
        grid-template-columns: 1fr;
        margin-left: 20px;
    }
`
const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        margin-left: 30px;
    }

    @media screen and (max-width: 500px) {
        grid-template-columns: 1fr;
        margin-left: 20px;
    }
`
const HowItWorksBox = styled.div`
    height: 100%;
    width: 100%;
    padding: 2 rem;
    transition: 0.3s;
`

const Icon = styled.div`
    font-size: 3rem;
    margin-bottom: 1rem;
`

const Title = styled.div`
    font-size: clamp(0.8rem, 2.5vw, 1.2rem);
    margin-bottom: 0.5rem;
`

const Description = styled.p`
    font-size: 1.3rem;
`