import React from 'react'
import styled from 'styled-components'
import Slide from 'react-reveal/Slide';

const FAQs = () => {
    return (
        <FAQContainer>
            <Slide left>
                <Description>
                    Frequently Asked Questions
                </Description>
            </Slide>
            <ContentWrapper>
                <Slide left>
                    <Question>
                        <h3>How does this work?</h3>
                    </Question>
                </Slide>
                <Slide left>
                    <Answer>
                        <ul>
                            <li>You book appointment with us by calling us at 518-386-8678. </li>
                            <li>You send us script/lab test prescription along with your insurance information for the lab to bill you for the tests.</li>
                            <li>Our expert phlebotomist perform the blood draw, centrifuge the specimen within the recommended timeframe and maintain a consistent temperature range till its delivered to the lab of your choice.</li>
                            <li>We deliver the blood work to your preferred lab.</li>
                            <li>Results: Your doctor shares the lab test results with you in the same manner as before. We take care of getting your blood specimens to the lab.</li>
                        </ul>
                    </Answer>
                </Slide>
                <Slide left>
                    <Question>
                        <h3>Who will perform my blood work?</h3>
                    </Question>
                </Slide>
                <Slide left>
                    <Answer>
                        We employ seasoned phlebotomists having vast experience performing blood draws, maintain confidentiality and deliver accurate specimen to the lab of your preference.
                    </Answer>
                </Slide>
                <Slide left>
                    <Question>
                        <h3>Payment Method</h3>
                    </Question>
                </Slide>
                <Slide left>
                    <Answer>
                        At this time, our services can only be purchased with cash, check, credit card, or a debit card.
                    </Answer>
                </Slide>
                <Slide left>
                    <Question>
                        <h3>Which laboratory do we send specimen to?</h3>
                    </Question>
                </Slide>
                <Slide left>
                    <Answer>
                        Laboratory of your choice in the capital region. We centrifuge the specimen within the specified timeframe and maintain a consistent temperature range.
                    </Answer>
                </Slide>
                <Slide left>
                    <Question>
                        <h3>Is it safe to allow phlebotomist at my home?</h3>
                    </Question>
                </Slide>
                <Slide left>
                    <Answer>
                        Totally. We perform stringent background checks against criminal records, licenses, education, and employment history. We are fully insured company.
                    </Answer>
                </Slide>
                <Slide left>
                    <Question>
                        <h3>Service Area</h3>
                    </Question>
                </Slide>
                <Slide left>
                    <Answer>
                        Currently, we serve the Capital Region.
                    </Answer>
                </Slide>
            </ContentWrapper>
            
        </FAQContainer>
        
    )
}

export default FAQs

const FAQContainer = styled.div`
    background: #fcfcfc;
    color: #000;
    padding: 2rem calc((100vw - 1300px) / 2);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 120vh;

    @media screen and (max-width: 768px){
        grid-template-columns: 1fr;
        margin-bottom: 5rem;
        margin-left: 30px;
        height: 200vh;
    }

    @media screen and (max-width: 350px){
        height: 250vh;
        margin-bottom: 2rem;
        padding-bottom: 2rem;
    }
`
const Description = styled.div`
    text-align: start;
    margin-bottom: 2rem;
    font-size: clamp(1.5rem, 5vw, 2rem);
    font-weight: bold;    
`
const ContentWrapper = styled.div`

    padding-top: 1rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
`
const Question = styled.div`
    h3{
        margin-bottom: 1rem;
        font-size: 1.5rem;
        font-style: italic;
    }
`
const Answer = styled.div`
    padding-bottom: 2rem;
    li{
        color: #3b3b3b;
    }
`