import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'

const Footer = () => {
    return (
        <FooterContainer>
            <FooterLinksWrapper>
                <FooterDesc>
                    <h1>Vein-U Mobile Blood Draw</h1>
                    <p>We thrive to create the best experiences for our customers</p>
                </FooterDesc>
                <FooterLinkItems>
                    <FooterLinkTitle>Contact Us</FooterLinkTitle>
                    <FooterLink to="/about">Phone - (518) 386 8678</FooterLink>
                    <FooterLink to="/about">Fax - (518) 671 3250</FooterLink>
                    <FooterLink to="/">Email -  veinucare@gmail.com</FooterLink>
                </FooterLinkItems>
                <FooterLinkItems>
                    <FooterLinkTitle>Social Media</FooterLinkTitle>
                    <FooterLink to="https://www.facebook.com/profile.php?id=100069780000547" target="_blank">Facebook</FooterLink>
                    <FooterLink to="/">Instagram</FooterLink>
                    <FooterLink to="/">Twitter</FooterLink>
                </FooterLinkItems>
            </FooterLinksWrapper>
        </FooterContainer>
    )
}

export default Footer

const FooterContainer = styled.div`
    padding: 5rem calc((100vw - 1100px) / 2);
    color: #000;
    background: #fafafb;
`
const FooterLinksWrapper = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 50px;
    @media screen and (max-width: 820px){
        grid-template-columns: 1fr;
    }
`
const FooterDesc = styled.div`
    padding: 0 2rem;
    margin-top: 0.6rem;
    h1{
        margin-bottom: 1rem;
        color: #f26a2e;
    }

    @media screen and (max-width: 400px){
        padding: 1rem;
    }
`
const FooterLinkItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 2rem;
    
    @media screen and (max-width: 400px){
        padding: 1rem;
    }
`
const FooterLinkTitle = styled.h2`
    font-size: 18px;
    margin-bottom: 16px;
`
const FooterLink = styled(Link)`
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 14px;
    color: #3d3d4e;

    &:hover{
        color: #f26a2e;
        transition: 0.3s ease-out;
    }
`